/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/fancybox@2.1.5/dist/js/jquery.fancybox.pack.js
 * - /gh/studiogt/scripts@1.0.0/sgt.min.js
 * - /gh/studiogt/scripts@1.0.5/validate.min.js
 * - /gh/studiogt/scripts@1.0.5/sweetalert.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
